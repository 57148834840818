import React from 'react';
import { graphql } from 'gatsby';
import Container from 'components/Container';
import Block from 'components/Block';
import PageHeader from 'components/PageHeader';
import Breadcrumbs, { Props as BreadcrumbsProps } from 'src/components/Breadcrumbs';
import ServicesBlock from 'components/ServicesBlock';
import TestimonialBlock from 'src/components/TestimonialBlock';
import { resolveUrl } from 'src/helpers';
import FeatureCopyBlock from 'src/components/FeatureCopyBlock';
import Spacer from 'src/components/Spacer';
import CtaButton from 'src/components/CtaButton';
import CaseStudyQuery_CaseStudy from 'src/transformers/CaseStudyQuery_CaseStudy';
import KeyResultsBlock from 'src/components/KeyResultsBlock';
import Seo from 'src/lib/components/Seo';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import GeneralTile from 'src/components/tiles/GeneralTile';
import GenericGridBlock from 'src/components/GenericGridBlock';
import PageImages, { Image as PageImagesImage } from 'src/components/PageImages';
import ContentParser from 'src/components/ContentParser';
import BasicQuery_SitePage from 'src/transformers/BasicQuery_SitePage';

interface Props {
  data: GatsbyTypes.CaseStudySingleQuery,
  pageContext: {
    caseStudyId: string,
  },
}

export const query = graphql`
  query CaseStudySingle($caseStudyId: String, $prevId: String, $nextId: String) {
    wpCaseStudy(id: { eq: $caseStudyId }) {
      ...CaseStudyBasic
      ...CaseStudyWithTestimonials
      ...CaseStudyWithServices
      ...CaseStudyWithKeyResults
      ...CaseStudyWithPageImages
    }
    prevCaseStudy: wpCaseStudy(id: { eq: $prevId }) {
      ...CaseStudyWithHero
    }
    nextCaseStudy: wpCaseStudy(id: { eq: $nextId }) {
      ...CaseStudyWithHero
    }
  }
`;


const CaseStudyPage: React.FC<Props> = ({ data }) => {
  const caseStudy = CaseStudyQuery_CaseStudy(data.wpCaseStudy);
  if (caseStudy === null) return null;
  const [prevCaseStudy, nextCaseStudy] = Array.from([
    data.prevCaseStudy,
    data.nextCaseStudy,
  ], CaseStudyQuery_CaseStudy);

  const pageData = BasicQuery_SitePage(data.wpCaseStudy || {});

  const testimonialsArray = caseStudy?.linkedTestimonials || [];
  const [testimonial] = testimonialsArray;

  const copyBlock1 = caseStudy?.copyBlock1;
  const copyBlock2 = caseStudy?.copyBlock2;
  const copyBlock3 = caseStudy?.copyBlock3;

  const associatedServices = caseStudy?.linkedServices || [];

  const breadcrumbs: BreadcrumbsProps['items'] = [
    { label: 'Case Studies', url: resolveUrl.caseStudy('') },
    { label: pageData.navTitle },
  ];

  const pageImagesRaw = data.wpCaseStudy?.acf?.pageImages || [];

  const pageImages: PageImagesImage[] = pageImagesRaw.reduce<PageImagesImage[]>((acc, image) => {
    const imageData: IGatsbyImageData | undefined =
      image?.image?.localFile?.childImageSharp?.gatsbyImageData;
    if (imageData) {
      acc.push({
        data: imageData,
        alt: image?.image?.altText || '',
      });
    }
    return acc;
  }, []);

  return (
    <>
      <Seo
        title={pageData.metaTitle}
        // titlePath="Case Studies"
        description={pageData.metaDescription}
      />
      <PageHeader
        category="Case Studies"
        h1={pageData.h1}
        backgroundText={pageData.contentHtml}
      />
      <Container readableWidth>
        <Breadcrumbs items={breadcrumbs} />
        {pageData.contentHtml && (
          <Block>
            <ContentParser>{pageData.contentHtml}</ContentParser>
          </Block>
        )}
      </Container>

      {caseStudy?.keyResults && (
        <Block darkBg type="noPadding">
          <Container>
            <KeyResultsBlock keyResults={caseStudy.keyResults} />
          </Container>
        </Block>
      )}

      {copyBlock1 && (
        <Block>
          <Container>
            <FeatureCopyBlock copyHTML={copyBlock1} />
          </Container>
        </Block>
      )}
      {/*
      {pageImages.length > 0 && (
        <Spacer mt={-3} mb={-5}>
          <Container yPadding={0}>
            <TileGrid itemsPerRow={2}>
              {pageImages.map((image, i) => (
        <GatsbyImage key={i} image={image} alt={`Case Study Image ${i % 2 ? 'Right' : 'Left'}`} />
              ))}
            </TileGrid>
          </Container>
        </Spacer>
      )} */}

      {pageImages.length > 0 && (
        <PageImages images={pageImages} />
      )}

      {copyBlock2 && (
        <Block contain>
          <FeatureCopyBlock copyHTML={copyBlock2} />
        </Block>
      )}

      {testimonial && (
        <Block darkBg contain>
          <TestimonialBlock
            humanName={testimonial.name}
            humanTitle={testimonial.jobTitle}
            quoteHTML={testimonial.quoteCopyHTML}
          />
        </Block>
      )}

      {copyBlock3 && (
        <Block contain>
          <FeatureCopyBlock copyHTML={copyBlock3} />
          <Spacer mb={4} />
          <CtaButton type="primary" />
        </Block>
      )}

      {(prevCaseStudy || nextCaseStudy) && (
        <Block contain darkBg>
          <GenericGridBlock itemsPerRow={2} title="Case studies.">
            {[prevCaseStudy, nextCaseStudy].map((item) => (item !== null ? (
              <GeneralTile
                key={item._id}
                title={item.title}
                subtitle={item?.linkedClients?.length ? item.linkedClients[0].name : ''}
                href={resolveUrl.caseStudy(item.slug)}
                image={item.image}
              />
            ) : null))}
          </GenericGridBlock>
        </Block>
      )}

      {associatedServices.length > 0 && (
        <Block contain>
          <ServicesBlock services={associatedServices} title="Related services." />
        </Block>
      )}
    </>
  );
};

export default CaseStudyPage;
