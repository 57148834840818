import React from 'react';
import styled from 'styled-components';
import Spacer from 'components/Spacer';
import StatCountup, { Props as StatCountupProps } from '../KeyResultsBlock/StatCountup';

const isNumber = (candidate: Props['stat']): candidate is number => typeof candidate === 'number';

export interface Props {
  stat: number | string,
  label: string,
  prefix?: string | number,
  suffix?: string | number,
  delay?: number,
  underline?: boolean,
  countUpOptions?: StatCountupProps['countUpOptions'],
}

const HeroStatBlock:React.FC<Props> = ({
  stat,
  label,
  prefix,
  suffix,
  delay = 0,
  underline = false,
  countUpOptions,
}) => {
  return (
    <HeroStatBlockOuter className={underline ? 'underline' : ''}>
      <Stat>
        {prefix && <Prefix>{prefix}</Prefix>}
        {isNumber(stat) ? (
          <StatCountup
            stat={stat}
            delay={delay}
            countUpOptions={countUpOptions}
          />
        ) : (
          <StatStatic>{stat}</StatStatic>
        )}
        {suffix && <Suffix>{suffix}</Suffix>}
      </Stat>
      <Spacer py={2}>
        <Label>{label}</Label>
      </Spacer>
    </HeroStatBlockOuter>
  );
};
export default HeroStatBlock;

const HeroStatBlockOuter = styled.div`
  &.underline {
    /* padding-bottom: 10px; */
    border-bottom: 2px solid ${(p) => p.theme.palette.secondary};
  }
`;
const Stat = styled.div`
  font-size: 4rem;
  ${({ theme }) => theme.breakpoints.up('tablet')} {
    font-size: 5rem;
  }
`;
const Prefix = styled.span``;
const StatStatic = styled.span``;
const Suffix = styled.span``;
const Label = styled.div``;
