import React from 'react';
import styled from 'styled-components';
import HeroStatBlock from 'components/HeroStatBlock';
import { KeyResult } from 'src/models/CaseStudy';
import Spacer from 'components/Spacer';
import Typography from 'components/Typography';

export interface Props {
  keyResults: KeyResult[],
  title?: string,
}

const KeyResultsBlock:React.FC<Props> = ({
  keyResults,
  title = 'Key Results',
}) => (
  <KeyResultsBlockOuter>
    {title && (
      <Spacer pb={4}>
        <Typography variant="h3" as="p">{title}</Typography>
      </Spacer>
    )}

    {keyResults.map((keyResult, i) => (
      <Spacer key={keyResult.label} mb={i < (keyResults.length - 1) ? 5 : 1}>
        <HeroStatBlock
          label={keyResult.label}
          prefix={keyResult.prefix || undefined}
          suffix={keyResult.suffix || undefined}
          stat={keyResult.value}
          delay={i * 300}
          underline
        />
      </Spacer>
    ))}
  </KeyResultsBlockOuter>
);
export default KeyResultsBlock;

const KeyResultsBlockOuter = styled.div`
  max-width: 45em;
`;
