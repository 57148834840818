import React from 'react';
import styled from 'styled-components';
import { useCountUp } from 'react-countup';
import type { useCountUpProps as UseCountUpProps } from 'react-countup/build/useCountUp';
import { Waypoint } from 'react-waypoint';


const defaultCountUpOptions: Props['countUpOptions'] = {
  separator: ',',
  start: 0,
  startOnMount: false,
  duration: 1,
};

export interface Props {
  stat: number,
  delay?: number,
  countUpOptions?: Partial<Omit<UseCountUpProps, 'prefix' | 'suffix' | 'end' | 'delay'>>,
}

const StatCountup:React.FC<Props> = ({
  delay = 0,
  stat,
  countUpOptions: propCountUpOptions,
}) => {
  const countUpRef = React.useRef<HTMLSpanElement>(null);
  const timeoutRef = React.useRef<NodeJS.Timeout>();
  const [isVisible, setIsVisible] = React.useState(false);
  const countUpOptions = React.useMemo(() => ({
    ...defaultCountUpOptions,
    ...propCountUpOptions,
  }), [propCountUpOptions]);
  const { start } = useCountUp({
    ...countUpOptions,
    start: 0,
    end: stat,
    ref: countUpRef || undefined,
  });

  React.useEffect(() => {
    if (isVisible) {
      timeoutRef.current = setTimeout(() => { start(); }, delay);
    }
  }, [isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => () => {
    if (timeoutRef.current) window.clearTimeout(timeoutRef.current);
  }, []);

  return (
    <>
      <Waypoint onEnter={() => setIsVisible(true)} />
      <Val ref={countUpRef} />
    </>
  );
};
export default StatCountup;

const Val = styled.span``;
